<template>
  <!-- 编辑自定义底板布局 -->
  <BaseDesignCustomFloor :isCustomFloorEdit="true" v-bind="permisionsList" />
</template>

<script>
import BaseDesignCustomFloor from './module/baseDesignCustomFloor'
import { checkPermission } from '@/utils'
export default {
  components: {
    BaseDesignCustomFloor
  },

  data () {
    return {
      permisionsList: {
        hasDetail: checkPermission(['externaladmin:user:editCustomFloorLayout:detail']),
        hasDoEdit: checkPermission(['externaladmin:user:editCustomFloorLayout:update']),
      }
    }
  }
}
</script>

<style>
</style>
